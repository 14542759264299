import { useEffect, useState } from 'react';

import { isSSR } from '../helpers';

const query = '(prefers-reduced-motion: reduce)';

const usePreferReducedMotion = (): boolean => {
    const [prefersReducedMotion, setPrefersReducedMotion] = useState<boolean>(
        !isSSR ? window.matchMedia(query).matches : false,
    );

    useEffect((): () => void => {
        const mediaQueryList = window.matchMedia(query);

        const listener = (event: MediaQueryListEvent): void => setPrefersReducedMotion(event.matches);

        mediaQueryList.addEventListener('change', listener);

        return (): void => mediaQueryList.removeEventListener('change', listener);
    }, []);

    return prefersReducedMotion;
};

export default usePreferReducedMotion;
