import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Card } from '../../../components';
import { DownloadableFile } from '../../../entities/Media/Media';
import { LabeledDownloadButton } from '../..';

import './DownloadCard.scss';

export interface DownloadCardProps {
    hasSmallLabels?: boolean;
    title: string;
    files?: DownloadableFile[];
    className?: string;
}

const DownloadCard: FC<DownloadCardProps> = ({
    hasSmallLabels,
    title,
    files = [],
    className = '',
}): ReactElement => {
    const labelClassNames = classNames('download-card__button-label', {
        'download-card__button-label--is-small': hasSmallLabels,
    });

    return (
        <Card className={`download-card ${className}`}>
            <h2 className="download-card__title">
                {title}
            </h2>

            <div className="download-card__button-wrapper">
                {files.map(file => (
                    <LabeledDownloadButton
                        key={file.src}
                        file={file}
                        className="download-card__button"
                        labelClassName={labelClassNames}
                    />
                ))}
            </div>
        </Card>
    );
};

export default DownloadCard;
