import { FC, ReactElement } from 'react';

import { Card, LinkButton } from '../../../components';
import { Link } from '../../../entities/Link/Link';

import './LinksCard.scss';

export interface LinksCardProps {
    title: string;
    links: Link[];
    className?: string;
}

const LinksCard: FC<LinksCardProps> = ({
    title,
    links,
    className = '',
}): ReactElement => (
    <Card className={`links-card ${className}`}>
        <h2 className="links-card__title">
            {title}
        </h2>

        <div className="links-card__link-wrapper">
            {links.map(link => (
                <LinkButton
                    key={link.path}
                    to={link.path}
                    text={link.label}
                    className="links-card__link"
                />
            ))}
        </div>
    </Card>
);

export default LinksCard;
