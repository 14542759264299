import { generateRestApiQuery } from '../../helpers/query';

export const generateArticleDetailQuery = (slug: string): string => {
    const filters = {
        slug: {
            $eq: slug,
        },
    };

    const populate = {
        tags: { populate: ['*'] },
        article_category: { populate: ['*'] },
        image: { populate: ['*'] },
        downloads: { populate: ['*'] },
        seo: { populate: ['*'] },
    };

    return generateRestApiQuery({ filters, populate });
};
