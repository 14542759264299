import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { Wrapper } from '../../components';
import {
    ContentColumn,
    LabeledDownloadButton,
    LinksCard,
    TypeHeader,
} from '../../compositions';
import { ConnectedLatestArticles } from '../../connectors';
import { transformToLatestArticlesTitle } from '../../entities/@sections/LatestArticles/LatestArticlesTransformers';
import { Article } from '../../entities/Article/Article';
import { Link, LinkTarget } from '../../entities/Link/Link';
import { isSSR } from '../../helpers';
import { convertNodesToHtmlString, getNodesFromHtmlString } from '../../helpers/html';
import { SanitizeOptions } from '../../helpers/sanitizeHtml';
import useTrans from '../../hooks/useTrans';
import { Share } from '..';
import { ArticleDetailSkeletons } from './skeletons';

import './ArticleDetailRenderer.scss';

interface ArticleDetailRendererProps {
    isLoading: boolean;
    article?: Article;
    newsletterPath: string;
    linkedInPath: string;
    className?: string;
}

const ArticleDetailRenderer: FC<ArticleDetailRendererProps> = ({
    isLoading,
    article,
    newsletterPath,
    linkedInPath,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [introHtml, setIntroHtml] = useState<string>('');
    const [restHtml, setRestHtml] = useState<string>('');

    useEffect((): void => {
        if (!article) return;

        const bodyHtmlNodes = getNodesFromHtmlString(article.bodyHtml);

        const introNode = bodyHtmlNodes.slice(0, 1);
        const restNodes = bodyHtmlNodes.slice(1);

        const introHtmlString = convertNodesToHtmlString(introNode);
        const restHtmlString = convertNodesToHtmlString(restNodes);

        setIntroHtml(introHtmlString);
        setRestHtml(restHtmlString);
    }, [article]);

    const sanitizeOptions: SanitizeOptions = {
        allowedTags: [
            ...['figure', 'figcaption', 'colgroup', 'col'],
            ...['table', 'thead', 'tbody', 'th', 'tr', 'td'],
            ...['hr'],
            ...['iframe'],
        ],
        allowedAttributes: {
            iframe: ['src'],
        },
    };

    const stayInformedLinks = useMemo((): Link[] => {
        const newsletterLink: Link | undefined = newsletterPath
            ? {
                label: trans('containers.articleDetailRenderer.stayInformed.newsletterLabel'),
                path: newsletterPath,
            }
            : undefined;

        const linkedInLink: Link | undefined = linkedInPath
            ? {
                label: trans('containers.articleDetailRenderer.stayInformed.linkedInLabel'),
                path: linkedInPath,
                target: LinkTarget.blank,
            }
            : undefined;

        return [newsletterLink, linkedInLink].filter(Boolean) as Link[];
    }, [trans, newsletterPath, linkedInPath]);

    if (isLoading || !article) {
        return (
            <ArticleDetailSkeletons />
        );
    }

    const headerImages = article.image ? [article.image] : [];
    const latestArticlesTitle = transformToLatestArticlesTitle(article.category);

    return (
        <article className={`article-detail-renderer ${className}`}>
            <TypeHeader
                caption={article.category}
                titleHtml={`<h1>${article.title}</h1>`}
                location={article.location}
                date={article.date}
                images={headerImages}
            />

            <Share
                noSpacingTop
                className="article-detail-renderer__share"
            />

            <Wrapper className="article-detail-renderer__wrapper">
                <div className="article-detail-renderer__body-wrapper">
                    {isSSR ? (
                        <ContentColumn
                            paragraphHtml={article.bodyHtml}
                            sanitizeOptions={sanitizeOptions}
                            className="article-detail-renderer__body"
                        />
                    ) : (
                        <ContentColumn
                            introHtml={introHtml}
                            paragraphHtml={restHtml}
                            sanitizeOptions={sanitizeOptions}
                            className="article-detail-renderer__body"
                        />
                    )}

                    <aside className="article-detail-renderer__aside">
                        <div className="article-detail-renderer__aside-card-wrapper">
                            <LinksCard
                                title={trans('containers.articleDetailRenderer.stayInformed.title')}
                                links={stayInformedLinks}
                            />
                        </div>
                    </aside>
                </div>

                <div className="article-detail-renderer__download-button-wrapper">
                    {article.downloads.map(download => (
                        <LabeledDownloadButton
                            key={download.src}
                            file={download}
                            className="article-detail-renderer__download-button"
                        />
                    ))}
                </div>

            </Wrapper>

            <ConnectedLatestArticles
                title={latestArticlesTitle}
                categories={[article.category]}
                excludeArticleId={article.id}
                pageSize={3}
                className="article-detail-renderer__latest-articles"
            />
        </article>
    );
};

export default ArticleDetailRenderer;
